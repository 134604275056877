<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <div class="card card-body">
              <div class="row">
                <div class="search-wrapper panel-heading col-sm-4 mb-5">
                  <input class="form-control" type="text" v-model="searchQuery" placeholder="Procurar" />

                </div>
                <div class="search-wrapper panel-heading col-sm-4 mb-5">
                  <button
                      class="btn btn-success"
                      data-bs-toggle="modal"
                      data-bs-target="#addmembers">
                    <i class="ri-add-fill me-1 align-bottom"></i>
                    Novo usuário
                  </button>
                </div>


              </div>
              <div class="table-responsive table-card">
                <table class="table align-middle table-nowrap"
                       id="customerTable">
                  <thead class="table-light text-muted">
                  <tr>
                    <th class="sort" data-sort="currency_name" scope="col">ID</th>
                    <th class="sort" data-sort="current_value" scope="col">
                      Nome
                    </th>
                    <th class="sort" data-sort="pairs" scope="col">Email</th>
                    <th class="sort" data-sort="low" scope="col">CPF</th>
                    <th class="sort" data-sort="market_cap" scope="col">
                      Perfil
                    </th>
                    <th scope="col">Ação</th>
                  </tr>
                  </thead>
                  <!--end thead-->
                  <tbody class="list form-check-all">
                  <tr v-for="(data, index) of resultQuery" :key="index">
                    <td class="id">
                      {{ data.Id }}
                    </td>
                    <td>{{ data.Nome }}</td>
                    <td class="pairs">{{ data.email }}</td>

                    <td class="low">{{ data.CPF }}</td>
                    <td class="market_cap">{{ data.perfil_descricao }}</td>
                    <td>
                      <span>
                        <router-link class="text-reset text-decoration-underline"
                                     :to="{name : 'Usuário', params:  {id: data.Id }  }">
                          Detalhes
                        </router-link>
                      </span>
                    </td>
                  </tr>
                  </tbody>
                  <!--end tbody-->
                </table>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev disabled" href="#" v-if="page != 1" @click="page--">
                    Anterior
                  </a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li :class="{
                              active: pageNumber == page,
                              disabled: pageNumber == '...',
                            }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                        @click="page = pageNumber">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                    Próximo
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
        class="modal fade"
        id="addmembers"
        tabindex="-1"
        aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="myModalLabel">
              Novo usuário
            </h5>
            <button
                type="button"
                class="btn-close"
                id="ClodeModal"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="novo_nome" class="form-label"
                    >Nome</label
                    >
                    <input
                        type="text"
                        class="form-control"
                        id="novo_nome"
                        v-model="novo_usuario.nome"
                        placeholder="Nome"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="mb-3">
                    <label for="novo_email" class="form-label"
                    >E-mail</label
                    >
                    <input
                        type="email"
                        v-model="novo_usuario.email"
                        class="form-control"
                        id="novo_email"
                        placeholder="Novo email"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="mb-3">
                    <label for="novo_tipousuario"
                           class="form-label">
                      Perfil
                    </label>
                    <select class="form-control"
                            v-model="novo_usuario.tipousuario"
                            id="novo_tipousuario">
                      <option v-for="novo_tipousuario in novo_tipousuarios"
                              v-bind:value="novo_tipousuario.id"
                              :key="novo_tipousuario.id">
                        {{novo_tipousuario.descricao}}
                      </option>

                    </select>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="hstack gap-2 justify-content-end">
                    <button
                        type="button"
                        class="btn btn-light"
                        data-bs-dismiss="modal"
                    >
                      Fechar
                    </button>
                    <button type="button"
                            @click="NovoUsuario"
                            class="btn btn-success">
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!--end modal-content-->
      </div>
      <!--end modal-dialog-->
    </div>
    <!--end modal-->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "Usuários",
  page: {
    title: "Usuários",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ],
  },
  data() {
    return {
      title: "Usuários",
      items: [
        {
          text: "Usuários",
          href: "/",
        },
        {
          text: "Usuários",
          active: true,
        },
      ],
      data:[],
      page: 1,
      perPage: 15,
      searchQuery:'',
      pages: [],
      novo_tipousuarios : [
        {id:'1',descricao:'Administrador EASYGOD'},
        {id:'26',descricao:'Cirurgião'},
        {id:'27',descricao:'Navegador'},
        {id:'30',descricao:'Anestesista'},
        {id:'40',descricao:'Instrumentador'},
        {id:'50',descricao:'Consultório'},
        {id:'60',descricao:'Contabilidade'},
        {id:'70',descricao:'Secretaria'},
      ],
      novo_tipousuario : '',
      novo_usuario : {
        nome: '',
        email: '',
        tipousuario: '',
      }
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.data);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return  (
              data.Nome.toLowerCase().includes(search)||
              data.email.toLowerCase().includes(search) ||
              data.CPF.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  mounted() {
    console.log(this.searchQuery);
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  created() {
    this.LoadPacientes();
    //this.setPages();

  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    async LoadPacientes() {

      const result = await axios.get(process.env.VUE_APP_API +'/v1/usuario', {})
      //console.log(result.data);

      this.data = result.data;

      //this.paginate(this.data);
      this.setPages();
      //this.displayedPosts();

    },
    async NovoUsuario() {
      try {

        var data = this.novo_usuario;
        axios.post(process.env.VUE_APP_API+'/v1/novousuario/', data).then((data) => {
          console.log(data);

          if(data.data.error){
            this.showAlerta(data.data.error, 'error');
          } else {
            this.showAlerta('Cadastro realizado', 'success');
            //addmembers

           this.closeModal();
            this.LoadPacientes();
          }

        }).catch((e) => {
          console.log(e)
        })
      }catch (e){
        console.log(e);
      }
    },
    closeModal() {
      document.getElementById('ClodeModal').click();
    },

    showAlerta(texto,icon) {
      Swal.fire({
        icon: icon,
        title: texto,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>